import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";

const Downloads = () => {
  return (
    <Root>
      <SEO title="Downloads" />
      <h1>Downloads</h1>
    </Root>
  );
};

export default Downloads;
